<template>
  <div>
    <v-container class="px-0">
      <v-col cols="12">
        <v-row>
          <v-col cols="12" lg="6" md="6">
            <high-charts-drill-bar
              :id="1"
              :items="itemsReq"
              :visibleSubTitle="true"
              :subtitle="'SOLICITUD DE PEDIDOS X ÁREA'"
              :objDrilldown="objDrilldownReq"
              :name="'Área'"
              :valueSuffix="''"
            />
          </v-col>
          <v-col cols="12" lg="6" md="6">
            <high-charts-drill-pie
              :id="2"
              :items="itemsOrd"
              :visibleSubTitle="true"
              :subtitle="'SOLICITUD DE OC/OS POR ÁREA'"
              :objDrilldown="objDrilldownOrd"
              :name="'Área'"
              :valueSuffix="'USD'"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-container>
  </div>
</template>

<script>
import HighChartsDrillPie from "@/components/Utils/HighCharts/HighChartsDrillPie.vue";
import HighChartsDrillBar from "@/components/Utils/HighCharts/HighChartsDrillBar.vue";
export default {
  props: {
    itemsReq: null,
    objDrilldownReq: null,
    itemsOrd: null,
    objDrilldownOrd: null,
  },
  components: {
    HighChartsDrillPie,
    HighChartsDrillBar,
  },
  data() {
    return {};
  },
};
</script>

<style>
.tran {
  opacity: 0.7 !important;
}
</style>
