<template>
  <div>
    <v-container>
      <v-container>
        <v-row>
          <v-col cols="12">
            <!-- <s-toolbar :color="'#244093'" dark label="Reporte de Usos" class="tran"></s-toolbar> -->
            <v-card>
              <v-row style="margin-top: 4px; margin-left: 10px; margin: auto">
                <v-col cols="4" lg="4" md="4">
                  <!-- <s-text label="Ingrese año inicio" number v-model="txtYearInitial"></s-text> -->
                  <s-date label="Fecha Inicio" v-model="filter.DateBegin"></s-date>
                </v-col>
                <v-col cols="4" lg="4" md="4">
                  <!-- <s-text label="Ingrese año fin" number v-model="txtYearFin"></s-text> -->
                  <s-date label="Fecha Fin" v-model="filter.DateEnd"></s-date>
                </v-col>
                <v-col cols="4" lg="2" md="2" class="mt-4 pl-0">
                  <v-btn width="100%" elevation="0" :color="'info'" small @click="run()"
                    >Buscar</v-btn
                  >
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-tabs v-model="currentItem">
          <v-tab href="#Dashboard"> Dashboard</v-tab>
          <v-tab href="#Pedido"> Solicitud de Pedidos</v-tab>
          <v-tab href="#OC"> Solicitud de OC/OS</v-tab>
        </v-tabs>

        <v-tabs-items v-model="currentItem">
          <v-tab-item :value="'Dashboard'">
            <lgs-report-dasboard-drill
              :itemsReq="itemsReq"
              :objDrilldownReq="objDrilldownReq"
              :itemsOrd="itemsOrd"
              :objDrilldownOrd="objDrilldownOrd"
            >
            </lgs-report-dasboard-drill>
          </v-tab-item>

          <v-tab-item :value="'Pedido'">
            <v-col cols="12">
              <v-row>
                <v-col cols="12">
                  <v-card outlined>
                    <v-data-table
                      show-group-by
                      group-by="AreName"
                      dense
                      :items="itemsRequirement"
                      :headers="headersRequirement"
                    ></v-data-table>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-tab-item>
          <v-tab-item :value="'OC'">
            <v-col cols="12">
              <v-row>
                <v-col cols="12">
                  <v-card outlined>
                    <v-data-table
                      show-group-by
                      group-by="AreName"
                      dense
                      :items="itemsOrder"
                      :headers="headersOrder"
                    ></v-data-table>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </v-container>

    <modal-loading
      :processing="processing"
      :messageProcessing="messageProcessing"
    ></modal-loading>
  </div>
</template>

<script>
import _sQryConfigurationService from "@/services/QueryManager/QryConfigurationService.js";
import * as XLSX from "xlsx";
import LgsReportDasboardDrill from "./LgsReportDasboardDrill.vue";

export default {
  components: {
    LgsReportDasboardDrill,
  },

  data() {
    return {
      currentItem: "tab-Funciones",
      processing: false,
      messageProcessing: "",
      filter: {},

      //Requerimiento
      itemsReq: [],
      objDrilldownReq: [],
      groupReq: [],

      //Order Purchase
      itemsOrd: [],
      objDrilldownOrd: [],
      groupOrd: [],

      //Requerimiento
      itemsRequirement: [],
      headersRequirement: [
        {
          text: "Area",
          value: "AreName",
        },
        {
          text: "Prioridad",
          value: "Prioridad",
        },
        {
          text: "Estado",
          value: "ReqEstado",
        },
        {
          text: "Total",
          value: "Total",
        },
      ],

      //Order Purchase
      itemsOrder: [],
      headersOrder: [
        {
          text: "Area",
          value: "AreName",
        },
        {
          text: "Prioridad",
          value: "Prioridad",
        },
        {
          text: "Estado",
          value: "ReqEstado",
        },
        {
          text: "Total",
          value: "CountOne",
        },
        {
          text: "Monto",
          value: "Total",
        },
      ],
    };
  },

  methods: {
    run() {
      this.runViewRequirement();
      this.runViewOrderPurchase();
    },

    runViewRequirement() {
      this.messageProcessing = "";
      var report = {};
      report.QryNameProcedure = "";
      report.QryParamsArray = "DateBegin, DateEnd";
      report.QryParamsArrayValue = this.filter.DateBegin + ", " + this.filter.DateEnd; //this.DateBegin + "," + this.DateEnd + "," + this.number;
      report.QryNameProcedure = "LgsDashboard_RequirementxArea";

      this.processing = true;
      _sQryConfigurationService.querygeneral(report, this.$fun.getUserID()).then(
        (resp) => {
          if (resp.status == 200) {
            this.processing = false;
            this.itemsRequirement = resp.data;

            var sum = 0,
              objTmp = {},
              objTmpdrilldown = {};

            this.groupReq = [];
            this.itemsReq = [];
            this.objDrilldownReq = [];

            this.groupReq = _.groupBy(resp.data, "AreName");

            for (let prop in this.groupReq) {
              sum = 0;
              objTmp = {};
              objTmpdrilldown = {};

              this.groupReq[prop].map((i) => {
                sum = sum + i.Total;
              });

              //GRAFICO PIE
              objTmp.name = prop;
              objTmp.y = sum;
              objTmp.drilldown = prop;

              //GRAFICO DRILL
              objTmpdrilldown.id = prop;
              objTmpdrilldown.name = prop;
              objTmpdrilldown.data = [];

              this.itemsReq.push(objTmp);
              this.objDrilldownReq.push(objTmpdrilldown);

              this.groupReq[prop].map((i) => {
                objTmpdrilldown.data.push([i.Descripcion, i.Total]);
              });
            }
          }
        },
        (e) => {
          this.messageProcessing = e.response.data.Message;
          this.processing = true;
        }
      );
    },

    runViewOrderPurchase() {
      this.messageProcessing = "";
      var report = {};
      report.QryNameProcedure = "";
      report.QryParamsArray = "DateBegin, DateEnd";
      report.QryParamsArrayValue = this.filter.DateBegin + ", " + this.filter.DateEnd; //this.DateBegin + "," + this.DateEnd + "," + this.number;
      report.QryNameProcedure = "LgsDashboard_OrderPurchasexArea";

      this.processing = true;
      _sQryConfigurationService.querygeneral(report, this.$fun.getUserID()).then(
        (resp) => {
          if (resp.status == 200) {
            this.processing = false;
            this.itemsOrder = resp.data;

            var sum = 0,
              objTmp = {},
              objTmpdrilldown = {};

            this.groupOrd = [];
            this.itemsOrd = [];
            this.objDrilldownOrd = [];

            this.groupOrd = _.groupBy(resp.data, "AreName");

            for (let prop in this.groupOrd) {
              sum = 0;
              objTmp = {};
              objTmpdrilldown = {};

              this.groupOrd[prop].map((i) => {
                sum = sum + i.Total;
              });

              //GRAFICO PIE
              objTmp.name = prop;
              objTmp.y = sum;
              objTmp.drilldown = prop;

              //GRAFICO DRILL
              objTmpdrilldown.id = prop;
              objTmpdrilldown.name = prop;
              objTmpdrilldown.data = [];

              this.itemsOrd.push(objTmp);
              this.objDrilldownOrd.push(objTmpdrilldown);

              this.groupOrd[prop].map((i) => {
                objTmpdrilldown.data.push([i.Descripcion, i.Total]);
              });
            }
          }
        },
        (e) => {
          this.messageProcessing = e.response.data.Message;
          this.processing = true;
        }
      );
    },
  },
};
</script>

<style>
.tran {
  opacity: 0.7 !important;
}
</style>
